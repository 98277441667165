import {
    AccessToken,
    BeEvent,
    BentleyError,
} from "@itwin/core-bentley";
import { ViewerAuthorizationClient } from "@itwin/web-viewer-react";
import axios from "axios";



export class AuthClient implements ViewerAuthorizationClient {
    public readonly onAccessTokenChanged = new BeEvent<
        (token: AccessToken) => void
        >();
    protected _accessToken?: AccessToken;
    
    public async initialize() {

        let config = {
            method: 'post',
            url: 'https://uat.siisel.cz/api/PublicApi/GetItwinAccessToken',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        };

        try {
            let response = await axios.request(config);
            console.log(response)
            this._accessToken = response.data
            
        } catch (error) {
            console.error(error);
            throw error; 
        }
    }
    
    
    public async getAccessToken(): Promise<AccessToken> {
        if (!this._accessToken) {
            throw new BentleyError(401, "Cannot get access token");
        }
        return this._accessToken;
    }
}