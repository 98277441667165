import { XAndY, XYAndZ } from "@itwin/core-geometry";
import { BeButtonEvent, IModelApp, Marker, NotifyMessageDetails, OutputMessagePriority, StandardViewId } from "@itwin/core-frontend";

export class SmartDeviceMarker extends Marker {
    private _smartDeviceId: string;
    private _smartDeviceType: string;
    private _elementId: string;

    constructor(location: XYAndZ, size: XAndY, smartDeviceId: string, smartDeviceType: string, siiselData: any, elementId: string) {
        super(location, size);
    
        this._smartDeviceId = smartDeviceId;
        this._smartDeviceType = smartDeviceType;
        this._elementId = elementId;
        
        this.setImageUrl(`/${this._smartDeviceType}.png`);
        this.title = this.populateTitle(siiselData);
        this.label = this.populateLabel(siiselData);
        this.labelColor = "red";
    }

    private populateLabel(siiselData: any) {
        let dataCount = siiselData["@odata.count"];

        if(dataCount < 1)
            return;
        
        return siiselData.value[0].pointAlias
    }
    
    private populateTitle(siiselData: any) {
        let dataCount = siiselData["@odata.count"];
        
        if(dataCount < 1)
            return;
        
        let parameters = siiselData.value[0].parameters;

        let smartTable = "";
        for (const parameter of parameters) {
            smartTable += `
            <tr>
             <th>${parameter.name}</th>
             <th>${parameter.value}</th>
            </tr>
            `;
        };

        let point = "Bod " + siiselData.value[0].pointId; // Adjusted spacing for readability

        const smartTableDiv = document.createElement("div");
        smartTableDiv.className = "smart-table";
        smartTableDiv.innerHTML = `
            <h3>${point}</h3>
            <h4>Překročený varovný stav: NE</h4>
            <table>
             ${smartTable}
            </table>
            `;

        return smartTableDiv;
    }

    public onMouseButton(_ev: BeButtonEvent): boolean {
        if (!_ev.isDown) return true;

        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Proběhlo získání aktuálních informací ze zařízení " + this._smartDeviceId + " Vaším klinutím"));
        IModelApp.viewManager.selectedView!.zoomToElements(this._elementId, { animateFrustumChange: true, standardViewId: StandardViewId.RightIso });
        return true;

    }

}