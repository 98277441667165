
import {  DecorateContext, Decorator, IModelConnection, Marker, ScreenViewport } from "@itwin/core-frontend";
import { SmartDeviceMarker } from "../markers/SmartDeviceMarker";
import { UseItwinClient } from "../../hooks/useItwinClient";
import { UseSiiselClient } from "../../hooks/useSiiselClient";

export class SmartDeviceDecorator implements Decorator {
    private _iModel: IModelConnection;
    private _markerSet: Marker[];
    private _data: any;

    constructor(vp: ScreenViewport) {
        this._iModel = vp.iModel;
        this._markerSet = [];
        
        this.addMarkers();
    }

    private async addMarkers() {

        let itwinClient = UseItwinClient();
        let siiselClient = UseSiiselClient();
        let iModelData = await itwinClient.getSmartDeviceData();
        let siiselSettings =  await siiselClient.getItwinPoints(iModelData.map(value => value.id));

        for (let i = 0; i < siiselSettings.itwinMeasurementOutputs.length; i++) {
            let currentItem = siiselSettings.itwinMeasurementOutputs[i]
            let siiselData = await siiselClient.fetchDataFromPath(currentItem.urlPath);
            let currentImodelItem = iModelData.find(x => x.id === currentItem.pointDwgId);
            console.log(siiselData);

            this._markerSet.push(new  SmartDeviceMarker(
                { x: currentImodelItem.origin.x, y: currentImodelItem.origin.y, z: currentImodelItem.origin.z },
                { x: 50, y: 50 },
                currentImodelItem.smartDeviceId,
                currentImodelItem.smartDeviceType,
                siiselData,
                currentImodelItem.id));
        }
    }

    public decorate(context: DecorateContext): void {
        this._markerSet.forEach(marker => {
            marker.addDecoration(context);
        })
    }
}

