import { UiFramework } from '@itwin/appui-react';
import { QueryRowFormat } from '@itwin/core-common';

export const UseItwinClient = () => {

    const getSmartDeviceData = async () => {
        const query = `
    SELECT SmartDeviceId,
                    SmartDeviceType,
                    ECInstanceId,
                    Origin
                    FROM DgnCustomItemTypes_HouseSchema.SmartDevice
                    WHERE Origin IS NOT NULL
    `;

    // Initialize the connection
    const iModelConnection = UiFramework.getIModelConnection();
    if (!iModelConnection) {
        throw new Error('No iModel connection available');
    }
    
    
    // Query and directly collect the results into an array
    const results = [];
    for await (const row of iModelConnection.query(query, undefined, { rowFormat: QueryRowFormat.UseJsPropertyNames })) {
        results.push(row);
    }

    return results;
}

  return {
    getSmartDeviceData
  };
};

