import axios from 'axios';
import { UseGlobalSettings } from './useGlobalSettings';

export const UseSiiselClient = () => {


  const fetchDataFromPath = async (path : any) : Promise<any> => {

    const settings = UseGlobalSettings();
    if (!settings) {
      throw new Error('Global settings are not available.');
    }

            let config = {
            method: 'get',
            url: `${settings.siiselBaseUrl}${path}`
        };

        try {
            let response = await axios.request(config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


  const getItwinPoints = async (pointDwgIds : any) : Promise<any> => {

    const settings = UseGlobalSettings();
    if (!settings) {
      throw new Error('Global settings are not available.');
    }

    let data = JSON.stringify({
        "PointDwgIds": pointDwgIds,
        "Email": "test@test.cz",
        "Password": "heslo123",
        "ProjectId": settings.siiselProjectId
    });
    let config = {
        method: 'post',
        url: `${settings.siiselBaseUrl}api/PublicApi/GetItwinSettings`,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        data : data
    };

    try {
        let response = await axios.request(config);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error if you want to handle it further up the call stack
    }
  }

  return {
    getItwinPoints, fetchDataFromPath
  };
};
