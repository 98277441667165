export const UseGlobalSettings = () => {
  const urlParams = new URLSearchParams(window.location.search);
    let iModelIdInput = '';
    let projectIdInput = '5';
    let siiselBaseUrlInput = 'https://uat.siisel.cz/';

    if (urlParams.has("iModelId")) {
      iModelIdInput = urlParams.get("iModelId") as string;
    }
    if (urlParams.has("projectId")) {
      projectIdInput = urlParams.get("projectId") as string;
    }
    if (urlParams.has("env")) {
      siiselBaseUrlInput = 'https://uat.siisel.cz/';
    }
    if (urlParams.has("env") && urlParams.get("env") === 'uat') {
      siiselBaseUrlInput = 'https://uat.siisel.cz/';
    }
    if (urlParams.has("env") && urlParams.get("env") === 'prod') {
      siiselBaseUrlInput = 'https://siisel.cz/';
    }

    //parameters by root element data atribute
    const reactRoot = document.getElementById('itwin-input-parameters');
    if (reactRoot) {
      iModelIdInput = reactRoot.getAttribute('data-imodel-id') ?? '';
      projectIdInput = reactRoot.getAttribute('data-siisel-project-id') ?? '';
      siiselBaseUrlInput = reactRoot.getAttribute('data-siisel-base-url') ?? '';

    }

    return {
        siiselBaseUrl: siiselBaseUrlInput,
        siiselProjectId: Number.parseInt(projectIdInput),
        iModelId: iModelIdInput,
      };
  };

